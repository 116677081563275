#predictButton{
  color: #61dbfb;
  border-color: #61dbfb;
  background-color: #282c34;
}

#predictButton:hover{
  background-color: #61dbfb;
  color:white;
}
