.App {
  text-align: center;
  background-color: #282c34;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
}

html{
  background-color: #282c34;

}

.nav-button{
  color: #61dbfb;
  border-color: #61dbfb;
  background-color: #282c34;
}

.nav-button:hover, .nav-button.selected, .nav-button:active{
  background-color: #61dbfb!important;
  color:white!important;
  border-color: #61dbfb!important;
}

.nav-button:focus{
  box-shadow: 0 0 0 0.2rem #61dbfb!important;
}

.App-nav{
  margin-bottom: 10px;
}

.client .client-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
